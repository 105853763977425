import React, { FunctionComponent } from 'react'

import './about.css'

type AboutProps = {

}

const About: FunctionComponent<AboutProps> = (props) => {
  return (
    <section id='about'></section>
  )
}

export default About