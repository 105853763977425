import React from 'react'

type Props = {}

const Experience = (props: Props) => {
  return (
    <section id='experience'></section>
  )
}

export default Experience