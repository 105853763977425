import React from 'react'

type Props = {}

const Services = (props: Props) => {
  return (
    <section id='services'></section>
  )
}

export default Services