import React from 'react'

type Props = {}

const Portfolio = (props: Props) => {
  return (
    <section id='portfolio'></section>
  )
}

export default Portfolio;