import React, { FunctionComponent } from 'react'

import './contact.css'


type ContactProps = {

}

const Contact: FunctionComponent<ContactProps> = (props) => {
  return (
    <section id='contact'></section>
  )
}

export default Contact