import React from 'react'

type Props = {}

const Footer = (props: Props) => {
  return (
    <div></div>
  )
}

export default Footer;